<script setup lang="ts">
import type { VirtualBaseCarouselSwiperOptions } from '../VirtualBaseCarousel.vue'

const {
  fullWidth = false,
  reviews = [],
  swiperOptions = {},
} = defineProps<{
  swiperOptions?: VirtualBaseCarouselSwiperOptions
  fullWidth?: boolean
  reviews?: string[]
}>()

const { isMobile } = useBreakpoint()
const { trackEvent } = useTracking()

const changeSlide = (i: number) => {
  if (i)
    trackEvent({
      event: 'client_feedback_click',
    })
}
</script>

<template>
  <div
    :class="[
      'mb-9 bg-beige px-4 py-9 sm:mx-0 sm:px-6',
      { 'w-full': fullWidth },
    ]"
  >
    <div
      :class="{
        'mx-auto max-w-screen-sm px-4 sm:px-6 lg:max-w-container-xl': fullWidth,
      }"
    >
      <div class="font-monospace mb-5 text-2xl">
        {{ $t('house.reviews.title') }}
      </div>

      <VirtualBaseCarousel
        class-name="house-reviews-global__slider"
        navigation
        pagination
        :slides="reviews"
        :virtual="false"
        :swiper-options="swiperOptions"
        @slide-change-active-index="changeSlide"
      >
        <template #slider-content="{ slide, slideIndex }">
          <BaseReview :key="slideIndex" :review="slide" />
        </template>

        <template #navigation>
          <VirtualBaseCarouselPaginationV2 :navigation="!isMobile" />
        </template>
      </VirtualBaseCarousel>
    </div>
  </div>
</template>

<style>
.house-reviews-global__slider .swiper-wrapper {
  @apply pb-3.5;
}

.house-reviews-global__slider .swiper-pagination {
  @apply static flex items-center !w-auto mx-6;
}

.house-reviews-global__slider .swiper-pagination-bullet {
  @apply w-[5px] h-[5px] border border-gray-600;
}

.house-reviews-global__slider .swiper-pagination-bullet-active {
  @apply bg-gray-600 w-[8px] h-[8px] border-0;
}
</style>
